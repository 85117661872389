//GLOBAL
@import "globals/_base.scss";
@import "globals/_generics.scss";
@import "globals/_fonts.scss";
@import "modules/_breakpoints.scss";


//Shared Pages
@import "_login.scss";


//Santa
@import "views/santa/_sidebar.scss";
@import "views/santa/dashboard.scss";
@import "views/santa/details";
@import "views/santa/reports.scss";
@import "views/santa/detailsNew.scss";
@import "views/santa/approval.scss";

//LOC INFO
@import "views/loc_info/_locinfo.scss";


//CS
@import "views/cs/_cs.scss";


//Assets
@import "views/assets/_tech.scss";


//Ticker
@import "_ticker.scss";