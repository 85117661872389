.ticker-header-img{
  width:100%;
  margin-top:9px;
}

.ticker-container select{
  -webkit-appearance: none;
}


.ticker-info-row label {
  font-weight:bold;
  font-size:18px;
}
.ticker-info-row input{
  font-weight:bold;
  font-size:14px;
  pointer-events:none;
}
.ticker-info-row{
  margin-bottom:8px;
}


.ticker-loader {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index:2;
}

.ticker-loader img {
  padding: 0px;
  position: fixed;
  left: 50%;
  top:15%;
  transform: translateX(-50%);
  display:none;
}

.ticker-info-row .form-group{
  margin-bottom:3px !important;
}

.ticker-btn-row .btn-group button{
  border:1px solid black;
}

.ticker-btn-row .btn-group .active-btn{
  background-color:#007bff;
  color:white;
}

.form-group .row label{
  font-size:10px;
  padding:0;
  margin:0;
  margin-left:10px;
  opacity:.7;
}


.negative-num{
  color:red;
}

.positive-num{
  color:green;
}


.select-wrap
{
  margin-top:10px;
  white-space:nowrap;
  border: 1px solid #777;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 0 5px 5px;
  width:100%;
  background-color:#fff;
  overflow:hidden;
}

.select-wrap label
{
  display:inline;
  font-size:16px;
  padding: 10px 8px 0;
  margin-right:60%;
}

select
{
  text-align:right;
  display:inline;
  background-color: #fff;
  border:0px;
}


@media(min-width: 900px){

  .ticker-container{
    width:40%;
    margin:auto;
  }

  body{
    margin-bottom:0px;
  }

  .ticker-header-img{
    margin-top:20px;
    width:auto;
  }

  .select-wrap{
    width:50%;
  }
}

