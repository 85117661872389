body{
  background-color: rgba(218, 223, 225, 1);
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Helvetica';
  -webkit-overflow-scrolling: touch;
}

ul{
  list-style: none;
}
input[type='checkbox']{
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

html{
  scrollbar-width: none !important;
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
  .info-filter-row{
    position: static !important;
  }
}

.no-big-scroll{
  overflow:hidden;
}

@media only screen and (max-width: 767px){
  .no-big-scroll{
    overflow:scroll;
  }
}

.scrollable-div{
  overflow-x : scroll !important;
}

.scrollable-div::-webkit-scrollbar{
  width: 17px !important;
  background: gray !important;
}