//LOGIN PAGE CSS

.login-container{
  max-width: 450px;
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 30px;
  transform: translateY(-5%);
}
.form-signin img{
  margin-bottom: 75px;
}
.form-signin input{
  margin-bottom: 2%;
}

@media(min-width: $screen-sm-min){
  .login-container {
    transform: translateY(5%);
  }
}


//MAIN PAGE CSS

.main-grid-container{
  min-height:100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

.main-header{
  text-align: center;
  margin-bottom:80px;
}

.main-content{
  text-align: center;
  margin-bottom:10px;
}
.main-nav-row{
  background-color: #5b6670;
}

.main-footer{
  text-align:center;
  margin-top:40px;
  margin-bottom:20px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main-grid-container .main-header {
    margin-bottom: 20px !important;
  }

  .main-grid-container .main-content{
    margin-bottom:20px !important;
  }
}


//ADMIN PAGE CSS



@media only screen and (min-width: 800px) {
  .admin-body-grid {
    grid-template-columns: 50% 50%;
  }
}

.admin-body-grid{
  min-height:100%;
  display: grid;
  grid-column-gap: 20px;
  grid-template-rows: auto 1fr auto;
}

.admin-body-inner-grid{
  display:grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 50% 50%;
}

.admin-header{
  text-align:center;
}

.admin-log-table{
  border-radius: 5px;
  background-color: white;
  height: 50vh;
  overflow:scroll;
}


