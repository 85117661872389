@media(max-width: 700px){
  .media-hidden-data-sm{
    display: none !important;
  }
}

@media (max-width: 1110px){


  .loc-info-grid-x{
    display: grid;
    grid-template-columns: 1fr !important;
    grid-gap: 2em;
  }

  .loc-info-grid-x .loc-info-table {
    margin-bottom:10px;
  }

  .loc-info-santa{
    height: 100%;
  }

  .loc-info-map{
    width: 90%;
    margin: 0 auto;
  }


  .media-hidden-data{
    display:none !important;
  }
  .header-fixed > thead > tr > th {
    width: 40% !important;
    float:left !important;

  }
  .header-fixed > tbody > tr > td {
    width: 40% !important;
    float:left !important;

  }
  .header-fixed > tbody {
    padding-bottom:250px;
  }

  .loc-info-details-main {
    max-height: 75vh !important;
    padding-bottom: 450px !important;
  }
  .loc-info-details-main #map{
    width: 90%;
  }


}

.table-borderless td,
.table-borderless th {
  border: 0 !important;
}


.locinfo-lable{
  font-weight:bold;
}

@media (min-width: 1110px) {


  .loc-info-grid-x{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2em;
  }

  .loc-info-table{
    grid-row: 1/3;
  }
  .loc-info-map{
    grid-column: 2/4;
    height:300px;
  }


  .loc-info-santa{
    grid-column: 2/4;
    grid-row: 2/4;
    margin-top: 100px;
  }

}


@media(max-width:1300px){
  .loc-info-details-main{
    overflow: scroll !important;
  }
}

.filters-col > label{
  margin-top:20px;
}
.filters-col label:first-child{
  margin-top:0px;
}

.info-export-btn{
  display: inline !important;
  text-align: right !important;
}

#map {
  height: 400px;
  width: 100%;
}

.grid-container{
  display:grid;
  grid-template-columns: 2fr 4fr;
  grid-gap: 1em;
}
.nested{
  display:grid;
  grid-auto-columns: 1;
  grid-auto-rows:2;
  row-gap: 1em;
}



.grid-container > div{
  padding:1em;
}


@media(min-width:960px){
  .mobile-table{
    table-layout: fixed;
  }
}

