.tableFixHeadBig    { overflow-y: auto; max-height: 75vh;}
.tableFixHeadBig:after{ content: '';display:block;padding-bottom: 225px}
.tableFixHeadBig th { position: -webkit-sticky; position:sticky; top: -2; background-color:#e8ecf1; color:black;}
.tableFixHeadBig {border-radius:5px}

.tableFixHeadBig2    { overflow-y: auto; max-height: 90vh;}
.tableFixHeadBig2:after{ content: '';display:block;padding-bottom: 225px}
.tableFixHeadBig2 th { position: -webkit-sticky; position:sticky; top: -2; background-color:#e8ecf1; color:black;}
.tableFixHeadBig2 {border-radius:5px}

.close-table th{
  text-align:right;
}
.close-table td{
  text-align: left;
}

.promo-status-expired{
  color:red;
}
.promo-status-active{
  color:green;
}
.promo-status-upcoming{
  color: orange;
}

.promo-details-input{
  display: none;
}
.shipment-details-input{
  display: none;
}

.shipment-status-delivered{
  color: green;
}
.shipment-status-inprogress{
  color: blue;
}
.asset-add-txt{
  display:none;
}

.equipment-table h1 {
  padding-bottom: 50px;
}
.details-tab {
  text-decoration: none;
  cursor: pointer;
  color: #6c7a89;
}
.details-tab:hover {
  text-decoration: none;
  color: black;
}
.equipment-table a:hover{
  text-decoration: none;
  color: black;
}

.hover-row-table tbody tr:hover {
  background-color: #76323F;

}

.hover-row-table tbody tr{
  padding: 5px !important;
}

table.hover-row-table{
  border:1px solid black;
  margin-top:6px;
}

table.hover-row-table > tbody > tr > td{
  padding:5px !important;
}

.inactive-item{
  display:none;
}


.details-table{
  padding:2em;
  background-color:#eee;
  border-radius: 9px;
  max-height: 100px !important;
  overflow:scroll;
}
.details-table > th {
  position: sticky;
  top: 0;
}


.details-table tr{
  padding:1em;
}
.activeItem{
  font-weight:bold;
  font-size:16px;
}

.loc-table-row{
  max-height: 50vh !important;
  overflow:scroll;
}

.header-btns{
  cursor: pointer;
  opacity:.6;
}

.header-btns-active{
  opacity:1;
}

.dept-Warehouse{
  background-color: #1e8bc3;
  color: #eee;
}

.dept-Tech{
  background-color: #eb974e;
}

.dept-Costuming{
  background-color: #23cba7;
}

.shipped{
  background-color:lightgray;
}

.clickable-roww{
  cursor: pointer;
}


.tablist-details{
  margin-bottom:20px;
}

.tablist-details li{
  display:inline;
  border:1px solid black;
  padding:12px;
  background-color:#1e8bc3;
  color:#eee;
  margin:0px 5px 0px 0px;

}



//COSTUMES

.pickticket-grid{
  display:grid;
  grid-template-columns: 1fr 4fr;
}




@media print {
  .print-container {
    visibility: hidden;
  }


  .print-area{
    visibility: visible;
    background-color: red;
    height: 100% !important;
    width: 100% !important;
    position: fixed !important;
    top: 30px !important;
    left: 30px !important;
    margin: 0 auto !important;
    padding: 15px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }
  .print-area #asset-box-table-count {
    text-align:left;
  }
  .print-area #asset-box-table-count td {
    text-align:right;
  }

}

