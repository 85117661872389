.container-grid{
  max-height: 100vh;
  max-width:100vw;
  overflow:scroll;
  display:grid;
  grid-template-columns: 2fr 3fr;
  grid-gap:2px;
  grid-template-rows: 100vh 40vh;
  justify-items:center;
}


#details-section{

  overflow:scroll;
}
#details-section .row{
  width:100% !important;
}

#assignment-section{

  grid-column:2;
  grid-row:1/3;
  justify-self:start;
}

#additional-info-section {
}