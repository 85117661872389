#cs-main-list{
  list-style-type: square !important;
}

.cs-links ul{
  list-style-type: square !important;
}
.cs-links li{
  list-style-type: square !important;
}

.cs-links a:hover{
  color:#d24d57;
}

#cs-main-list{

}

#cs-main-list li{
  margin-top: 20px;
}