.loc-assign-input{
  display:none
}

.santa-profile-pic{
  max-width: 90%;
  max-height: 300px;
  display: block;
  margin: auto;
  image-orientation: from-image;
}
.santa-image-display{
  max-width:90%;
  max-height: 75vh;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin: 0 auto;
}
.santa-image-test{
  width:200px !important;
  height:200px !important;
}

.md-pic-selected{
  opacity: .5;
}
.edit-santa-details{
  display:none;
}

#assign-form-pic{
  max-width: 90%;
  max-height: 500px;
  display: block;
  margin: auto;
}


#page-content-wrapper{
  position: relative;
  top: 60px;
}
#dashboard-content-wrapper{
  position: relative;
  top: 60px;
}

#page-header-row{
  background-color: #eee;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#page-header-row table{
  width: 90%;

}

#page-header-row table td{
  word-break: break-word;
}



#page-content-row{
  margin-top: 30px;
}

#page-content-list{
  margin: 0;
  padding: 0;
}

#page-content-list li{
  margin-bottom: 15px;
}

.expandable-div{
  background-color: #eee;
  padding-left: 10px;
  padding-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



.prev-assignment-contain{
  border-bottom: 2px solid black;
  margin-bottom: 20px;
}


#note-body{
  width: 100%;
  height: 100px;
}


@media(min-width: $screen-sm-min) and (max-width: $screen-md-min){
  #header-row-title{

  }
}

@media(min-width: $screen-sm-min){
  #note-body{

  }
}
@media(max-width: 767px){
  #photo-right{
    display:none;
  }
  #photo-left{
    display:none;
  }
}

.ui-loader{
  display: none;
}
#santa-assign-form .row{
  margin-bottom:20px;
}


.santa-assign-form-update{
  margin-top: 20px;
  margin-bottom: 5px;
}

.text-right-test{
  float: right !important;
  text-align: right !important;
}


.active{
  z-index:98;
}

.img-modal{
  max-width: 75%;
  max-height: 75%;
}







.expenses-breakdown-list{
  margin-top: 15px;
}
.expenses-breakdown-header{

}
.expense-breakdown-hidden{
  display:none;
}


.costume-input{
  display:none;
}


.exp-table-input{
  display: none;
}

#search-list-details{
  max-height: 200px;
  overflow: scroll;
  background-color: #eee;
  color: black;
  opacity: .8;
  padding-left: 0px;
  text-align: left;
}
#search-list-details li{
  padding-left: 3px;
  padding-top: 5px;
  cursor: pointer;

}
#search-list-details li:hover{
  background: #e74c3c;
}

.santa-daysoff-list{
  max-height: 200px;
  overflow: scroll;
  background-color: #eee;
  color: black;
  opacity: .8;
  padding-left: 0px;
  text-align: left;
}
.santa-daysoff-list li{
  padding-left: 3px;
  padding-top: 5px;
  cursor: pointer;

}
.santa-daysoff-list li:hover{
  background: #e74c3c;
}



.assignment-info-row{
  padding-top: 25px;
}
.prev-assignment-info-row{
  padding-top:25px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.assignment-santa-row{
  margin-bottom: 30px;
}

.assignment-dropdown-row{
  box-shadow: 8px 2px 0px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.assignment-dropdown-btn{
  padding: 2px;
}

.btn-blueish {
  background-color:rgb(52, 73, 94);
}

.details-dropdown-row{
  box-shadow: 4px 0px 2px 0px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.details-dropdown-btn{
  padding: 2px;
}

.assignments-section-new{
  box-shadow: 8px 2px 0px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.assignments-section{
  box-shadow: 8px 2px 0px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.details-page-shadow{
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.details-page-shadow-bottom{
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.assignment-dropdown{
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.details-middle-row{
  padding-top: 25px;
  padding-bottom: 50px;
  margin-left: 5px;
  margin-right: 5px;
}
.details-middle-col{
  margin-left: 5px;
  margin-right: 5px;
  height: 500px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.top-row-header{
  border-bottom: 1px solid black;
  margin-bottom: 15px;
}


.details-middle-col-info{
  height:100%;
}

.details-santa-image-row{
  margin-bottom: 25px;
}

.details-santa-photo-btn{
  margin-top: 100px;
}

.loc-search-row{
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}





.modal-display-img{
  max-width: 100%;
  max-height: 100%;
}





@media(max-width: 767px){
  .usertask_col{
    width:85% !important;
    margin:auto !important;
  }
}

.assignment-status-live:hover{
  cursor: pointer;
}

.exp-count{
  font-weight: bold;
}

.tr-form-btn{
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

.tr-form-btn:hover{
  color: purple;
}

.tr-btn{
  cursor: pointer;
}

.approved-status{
  color:#55efc4;
  font-weight:bold;
}


#tag-list li a{
  padding: 10px;
  background-color: gray;
  border-radius:5px;
  color: white;
  font-weight:bold;
  width:100%;
  margin:15px;
}
#tag-list li{
  margin:15px;
}

.tr-status-change{

}


.santa-info-section{
  margin-bottom:30px;
}


.grid-button-section button{
  margin:5px;
}


@media(max-width:767px){
  .grid-container-santa {
    margin-top:25px;
    display:grid;
    grid-template-columns: 1fr;
    grid-row-gap:1rem;


  }
  .grid-section {
    background-color:#eee;
    border:1px solid black;
    padding:10px;
  }
  .grid-main-2{
    display:grid;
    grid-template-columns: 1fr;
  }

  .header-grid{
    display:grid;
    grid-template-columns: 1fr;
  }

  #image-row{
    width:300px;
    white-space:nowrap;
    max-height:130px;
  }

  #santa-note-section{
    height:500px;
    overflow:scroll;
  }

}

@media(min-width:1201px){

  .grid-main-2{
    display:grid;
    grid-template-columns:1fr 1fr;
  }
}



@media(min-width:768px){
  .grid-container-santa{
    margin-top:25px;
    display:grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
  }

  .grid-section{
    background-color:#eee;
    border:1px solid black;
    padding:25px;
  }

  #image-row{
    width:800px;
    white-space:nowrap;
    max-height:130px;
  }


  .header-grid{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
  }


  .grid-header{
    grid-column-start:1;
    grid-column-end:3;
    grid-row-start:1;
    grid-row-end:2;
  }

  .button-grid{
    grid-column-start:1;
    grid-column-end:3;
    grid-row-start:2;
    grid-row-end:3;
  }

  .grid-side{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start:2;
    grid-row-end:5;
    max-height:700px;
    padding-bottom:300px;
    overflow:scroll;

  }

  .grid-main-1{
    grid-column-start:2;
    grid-column-end:3;
    max-height:700px;
    overflow:scroll;
  }



  #santa-note-section{
    grid-column-start:1;
    grid-column-end:3;
    height:500px;
    overflow:scroll;
  }
}

.assign-header{
  padding-bottom:1.2em;
  padding-top:1.2em;
  font-size:18px;
}

.img-thumbnail-santa{
  width:auto;
  height:100%;
}

.card-row{
  cursor:pointer;
}


@media(max-width: 767px){
  .card{
    width:95%;
  }
}

