html, body, #wrapper{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}
#wrapper{
  overflow: scroll;
}

#sidebar-wrapper{
  z-index: 5;
  position: fixed;
  top:0;
  width: 0;
  height: 100vh;
  overflow-y: hidden;
  background: #3d3d3d;
  color: #b2bec3;
  overflow: scroll;
  font-family: Poppins;
}

#sidebar-header{
  text-align: center;
}

#sidebar-avatar{
  margin-top: 5px;
  margin-bottom: 20px;
  width:100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#sidebar-search{
  margin-top: 20px;
}

#sidebar-search input{
  background-color: #636e72;
  border: none;
  color: #dfe6e9;
}

.sidebar-nav{
  background-color: #636e72;
  height: 50%;
  padding: 0;
  list-style: none;
  overflow: scroll;
  margin-bottom: 20px;
  margin-top: 20px;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  border-radius: 2%;
}

.sidebar-nav li{
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a{
  display: block;
  text-decoration: none;
  color: #eee;
}

.sidebar-nav li a:hover{
  background: #e74c3c;
}

#wrapper.menuDisplayed #page-content-wrapper{
  margin-left: 280px;
}

.small-input{
  max-width: 75px;
}

.form-align-right{
  margin-left: 2px;
}

#sidebar-footer{
  margin-top: 50px;
  width: inherit;
  overflow: hidden;
  text-align: center;
}

#wrapper.menuDisplayed #sidebar-wrapper{
  width: 280px;
  height: 100vh;
}

.sidebar-menu-row{
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: #3d3d3d;
  width: 100%;
  padding: 0px;
}

#wrapper.menuDisplayed .sidebar-menu-row{
  padding-left: 295px;
}

#menu-logo{
  -webkit-transform: translate(10px,0);
  -moz-transform: translate(10px,0);
  -ms-transform: translate(10px,0);
  -o-transform: translate(10px,0);
  transform: translate(10px,0);
}

#wrapper.menuDisplayed #menu-logo{
  -webkit-transform: translate(0,0);
  -moz-transform: translate(0,0);
  -ms-transform: translate(0,0);
  -o-transform: translate(0,0);
  transform: translate(0,0);
}

@media(max-width: $screen-sm-min){
  #wrapper.menuDisplayed #page-content-wrapper {
    display: none;
  }
}



@media(min-width: $screen-sm-min){

  #wrapper.menuDisplayed #sidebar-wrapper{
    width: 250px;
    height: 100vh;
  }

  #wrapper.menuDisplayed .sidebar-menu-row{
    padding-left: 265px;

  }
}


#sidebar-wrapper ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #dfe6e9;
}
#sidebar-wrapper :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #dfe6e9;
  opacity:  1;
}
#sidebar-wrapper ::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #dfe6e9;
  opacity:  1;
}
#sidebar-wrapper :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    #dfe6e9;
}
#sidebar-wrapper ::-ms-input-placeholder { /* Microsoft Edge */
  color:    #dfe6e9;
}

#sidebar-wrapper ::placeholder { /* Most modern browsers support this now. */
  color:    #dfe6e9;
}



.group-test-row{
  position:relative;
  top: 100px;
  left: 300px;
}

#search-list{
  max-height: 200px;
  overflow: scroll;
  background-color: #eee;
  color: black;
  opacity: .8;
  padding-left: 0px;
  text-align: left;
  display: none;
}
#search-list li{
  padding-left: 3px;
  padding-top: 5px;
}
#search-list li:hover{
  background: #e74c3c;
}


#search-list-2{
  max-height: 200px;
  overflow: scroll;
  background-color: #eee;
  color: black;
  opacity: 1;
  padding-left: 0px;
  text-align: left;
  display: none;
}
#search-list-2 li{
  padding-left: 3px;
  padding-top: 5px;
}
#search-list-2 li:hover{
  background: #e74c3c;
}