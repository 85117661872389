@media(max-width: 650px){
  .approval-grid{
    display:grid;
    grid-template-columns: repeat(1,1fr);
    grid-column-gap: 1em;
  }

  .santa-card{
    display:flex;
    flex-direction:column;
    max-width:380px;
    background-color:#eee;
    border-radius: 5px;
    padding: 5px 0px 5px 0px;
    box-shadow: 1px 1px 5px #555 inset;
    margin: auto;
    margin-bottom:10px;
  }
  .text-right-approval{
    text-align:left;
  }
}

@media(min-width: 651px) and (max-width: 920px){
  .approval-grid{
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 1em;
  }

  .santa-card{
    display:flex;
    flex-direction:column;
    max-width:380px;
    background-color:#eee;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;
    padding: 5px 0px 5px 0px;
    box-shadow: 1px 1px 5px #555 inset;
  }
  .text-right-approval{
    text-align:right;
  }
}

@media(min-width: 921px) and (max-width:1145px){
  .approval-grid{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 1em;
  }

  .santa-card{
    display:flex;
    flex-direction:column;
    max-width:380px;
    background-color:#eee;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;
    padding: 5px 0px 5px 0px;
    box-shadow: 1px 1px 5px #555 inset;
  }
  .text-right-approval{
    text-align:right;
  }
}

@media(min-width: 1146px) and (max-width:2200px){
  .approval-grid{
    display:grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 1em;
  }

  .santa-card{
    display:flex;
    flex-direction:column;
    max-width:380px;
    background-color:#eee;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;
    padding: 5px 0px 5px 0px;
    box-shadow: 1px 1px 5px #555 inset;
    column-gap: 10px;
  }
  .text-right-approval{
    text-align:right;
  }
}

@media(min-width: 2201px){
  .approval-grid{
    display:grid;
    grid-template-columns: repeat(8,1fr);
    grid-column-gap: 1em;
  }

  .santa-card{
    display:flex;
    flex-direction:column;
    max-width:380px;
    background-color:#eee;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;
    padding: 5px 0px 5px 0px;
    box-shadow: 1px 1px 5px #555 inset;
    column-gap: 10px;
  }
  .text-right-approval{
    text-align:right;
  }
}


@media(max-width: 1294px){
  .approvalHeader {
    margin-top:400px;
  }
  .approvalImage{
    width:50% !important;
    height: auto !important;
  }

}

@media(min-width: 1295px){
  .approvalHeader {
    margin-top:285px;
  }

}

@media(max-width:730px){
  .approvalMessage{
    position:relative !important;
  }
  .approvalHeader{
    margin-top:100px;
  }
}





.approval-section{
  margin-top:auto;
  padding-top:10px;
}

.santa-removed-card{
  opacity:.5;
}

.santa-header-name{
  text-align:center;
  margin-bottom:12px;
}

.breaker-row{
  padding-bottom:10px;
}