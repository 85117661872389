/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../../../../public/fonts/poppins/poppins-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('../../../../public/fonts/poppins/poppins-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../../public/fonts/poppins/poppins-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../../public/fonts/poppins/poppins-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../../../public/fonts/poppins/poppins-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../../public/fonts/poppins/poppins-v5-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/open-sans/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('/assets/fonts/open-sans/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/open-sans/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/open-sans/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/open-sans/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/open-sans/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/montserrat/montserrat-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat Regular'),
  url('/assets/fonts/montserrat/montserrat-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat/montserrat-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat/montserrat-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

