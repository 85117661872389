@media(max-width: 700px){
  .hidden-data{
    display: none !important;
  }

  .card{
    width:100% !important;
  }

}


.dashboard-row{
  margin-left: 3px;
  margin-right: 3px;
  max-height: 90vh;
}

.dashboard-list-col{
  max-width: 500px;
  min-width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-list-col-lg{
  max-width: 600px;
  min-width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-list-graph{
  width: 100%;
  max-height: 80vh;
  overflow:scroll;
  padding-bottom: 150px;
}

.dashboard-list-graph-lg{
  width: 100%;
  max-height: 90vh;
  overflow:scroll;
  padding-bottom: 150px;
}


.dashboard-list-el{
  padding: 10px;
  border-bottom: 1px solid black;
  font-size: 16px;

}
#test-circle{
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.moving-on-up{
  position: relative;
  top: -105px;
}

#complete-santas-dash{

  overflow: hidden;

}
#complete-santas-list{
  margin-top: 5px;
  max-height: 100%;
  width: 100%;
  overflow: scroll;
}

#reports-dash{

}



.title-test{
  background-color:#6c7a89;
  color:#eee;
  padding-left: 0;
  padding-right: 0;
}

.dashboard-list-title{
  padding: 5px;
  width: 100%;
  -webkit-border-radius: 3%;
  -moz-border-radius: 3%;
  border-radius: 3%;
}

#dashboard-report-list{
  margin-top: 30px;
}
@media(min-width: 1205px) {
  .dashboard-list-graph{
    height: 95vh;
    overflow: scroll !important;
    width: 100%;
  }
  .dashboard-list{
    height: 95vh;
    overflow: scroll !important;
    width: 100%;
  }
  .dashboard-row{
    overflow: hidden;
  }

}


.clickable-row{
  cursor: pointer;
}
.clickable-row:hover{
  background-color: #bdc3c7 !important;
}


.circliful-contain{
  width: 80%;
  margin:auto;
}