.santa-reports-grid{
  display:grid;
  grid-column-gap: 20px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  grid-row-gap: 30px;
  margin-left: 30px;
}

.santa-inner-grid{
  display: grid;
  grid-column-gap: 10px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 50% 50%;
  grid-row-gap: 10px;

}


@media only screen and (min-width: 800px) {
  .santa-reports-grid {
    grid-template-columns: 50% 50%;
  }
}


.welcome-bold{
  font-weight:bold;
}

.welcome-under{
  font-weight:bold;
  text-decoration:underline;
}

