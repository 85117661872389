.var-header-underline{
  font-weight: bold;
  border-bottom: 1px solid #3d3d3d;
  margin-bottom: 15px;
}

.var-header{
  font-weight: bold;
}

.tabbed-right{
  padding-left: 5em;
}
.border-top{
  border-top: 1px solid black;
}

.border-bottom{
  border-bottom: 1px solid black;
}

.expand-hidden{
  display: none;
}
.hidden{
  display: none;
}

.margin-top-ten{
  margin-top: 10px;
}

.margin-top-thirty{
  margin-top: 30px;
}

.margin-bottom-twenty{
  margin-bottom: 20px;
}

.spaced-row{
  margin-bottom: 20px;
}
.slightly-spaced-row{
  margin-bottom: 8px;
}

.green{
  color: green;
}

.sm-center-content{
  text-align: center;
}

.filled-header-row{
  background-color: #3d3d3d;
  color: #eee;
  padding: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.filled-row-title{
  background-color: #3d3d3d;
  color: #eee;
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 2px;
}

.expanded-table{
  word-break: break-word;
}

.icon-image{
  width: 30px;
  height: 30px;
}
.sm-icon-image{
  width: 20px;
  height: 20px;
}
.xs-icon-image{
  width: 10px;
  height: 10px;
}

.form-label{
  font-weight: bold;
  display: block;
}

textarea{
  max-width: 90%;
}


ul li{
  list-style-type: none !important;
}
ul {
  list-style-type: none !important;
}


#page-content-row li{
  list-style-type: none !important;
}

#page-content-list{
  list-style-type: none !important;
}

.margin-top-btn{
  margin-top: 20px;
}

a {
  text-decoration: inherit;
  color: inherit;
}
a:hover{
  text-decoration: inherit;
  color: inherit;
}
a:visited{
  text-decoration: inherit;
  color: inherit;
}

.margin-left-twenty{
  margin-left: 20px;
}
.margin-right-twenty{
  margin-right: 20px;
}
.padding-bottom{
  padding-bottom: 15px;
  padding-top: 3px;
}
.table-center{
  margin:auto;
  width:50% !important;
}

.scrollable-col{
  height: 100%;
  overflow:scroll;
  width: 100%;
  padding-bottom: 100px;
}

.report .table tbody tr:hover td, .report .table tbody tr:hover th {
  background-color: #d24d57;
  cursor: pointer;
}

.phone-link{
  text-decoration: underline;
  color: blue;
}

.header-fixed {
  width: 100%
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
  display: block;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

.header-fixed > tbody {
  overflow-y: auto;
  height: 50vh;
  padding-bottom:100px;
}

.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
  width: 16%;
  float: left;
}

.btn-purple{
  background-color:#bf55ec;
}
.btn-orange{
  background-color:#f89406;
}

.btn-redish{
  background-color: #d2527f;
}

.btn-purplish{
  background-color: #22a7f0;
}

.btn-green{
  background-color: #eb974e;
}


.tableFixHead    { overflow-y: auto; max-height: 58vh;}
.tableFixHead:after{ content: '';display:block;padding-bottom: 225px}
.tableFixHead th { position: -webkit-sticky; position:sticky; top: -2; background-color:#e8ecf1; color:black;}
.tableFixHead {border-radius:5px}

.clickable-row-info:hover{
  background-color: #abb7b7 !important;
}

.clickable-row-info{
  cursor: pointer;
}

.red{
  background-color: red;
}
.white{
  background-color: white;
}

.clickable:hover{
  cursor: pointer;
  background-color: #d24d57;
}

.click-row:hover{
  cursor: pointer;
  background-color: #d24d57;
}


.rotate-90{
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}


.compare-green{
  color: green;
}
.compare-red{
  color: red;
}

.rotate-right{
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.no-firefox-scroll{
  scrollbar-width: none !important;
}

.circle{
-webkit-border-radius:8px;
  -moz-border-radius:8px;
  border-radius:8px;
  border:1px solid;
  width:16px;
  height:16px;
  text-align: center;
}

.low-opacity{
  opacity: .2;
}

.centered{
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control-sucks{
  border-radius: 2px;
  border: none;
  width:80%;
  padding:7px;
  margin-bottom:3px;
}

.form-control-sucks2{
  border-radius: 2px;
  border: none;
  width:60%;
  padding:7px;
  margin-bottom:3px;
}

